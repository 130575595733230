import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';

export class FormVille extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_Region: [],
            selectedData: null,
            idVille: '',
            libelleVille: '',
            idRegion: null, // Clé étrangère de la table Région

            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();

    }
 
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_region();
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_ville().then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    //Liste des Région
    get_liste_region() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_region().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idRegion, label: x.libelleRegion }
                });
                this.setState({ liste_Region: liste });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.get_liste_data();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({submit: false});
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ libelleVille: data.libelleVille });
            this.setState({ idRegion: data.idRegion.idRegion });
            this.setState({ modif: true });
            this.setState({ selectedData: data });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_data() {
        this.setState({ libelleVille: '' });
        this.setState({ idRegion: null });
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
    }


    enreg_data() {
        this.setState({submit: true});
        if (this.state.libelleVille == '' || this.state.idRegion == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.modif == false) {
            this.setState({ showindicator: true });
            let data = {
                idVille: 0,
                libelleVille: this.state.libelleVille,
                idRegion: { idRegion: this.state.idRegion }
            }
            this.CISService.enreg_ville(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedData;
            data.libelleVille = this.state.libelleVille;
            data.idRegion = { idRegion: this.state.idRegion };
            this.CISService.enreg_ville(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_data(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_data() {
        if (this.state.libelleVille == null || this.state.libelleVille == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_ville(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ libelleVille: data });
            this.setState({ msg: data.libelleVille });
            this.setState({ visible: true });
            this.setState({ selectedData: data });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle region */
    toogleDlg() {
        this.annule_data();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des regions  */}
                <DataTable value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                    responsive={true} selection={this.state.selectedData} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="libelleVille" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '45%' }} />
                    <Column field="idRegion.libelleRegion" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '45%' }} />
                </DataTable>

                {/* Boite de dialogue d'ajout et de modification d'une region  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_data()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="libelleVille">Ville *</label>
                            <InputText id="libelleVille" value={this.state.libelleVille} onChange={(e) => this.setState({ libelleVille: e.target.value })} required autoFocus />
                            {this.state.submit && !this.state.libelleVille && <small className="p-invalid" style={{ color: 'red' }}>Le nom de la ville est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="region">Region *</label>
                            <Dropdown id="region" options={this.state.liste_Region} value={this.state.idRegion} onChange={(e) => this.setState({ idRegion: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                            {this.state.submit && !this.state.idRegion && <small className="p-invalid" style={{ color: 'red' }}>La region est obligatoire.</small>}
                        </div>
                    </div>
                </Dialog>


            </div>

        );
    }
}