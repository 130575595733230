import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CISService from '../service/CISService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Knob } from 'primereact/knob';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';

const pieOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
};

const barchartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};

const doughnutData = {
    labels: [],
    datasets: [
        {
            data: [],
            backgroundColor: [
                '#0479b6',
                "#42846a",
                "#97bbcd",
                "#11a8ab",
                "#0e4c70",
                "#5a6267",
                "#3f95c2",
                "#d78838",
                "#4eae6a",
                "#1A5276"
            ],
            hoverBackgroundColor: [
                '#1d8fca',
                "#5ca789",
                "#b0d3e5",
                "#18ced2",
                "#1a6d9d",
                "#818d94",
                "#4aaee3",
                "#f1a252",
                "#5dda82",
                "#2a6e9a"
            ]
        }
    ]
};

const ordersChartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: 'index',
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0,
                    max: 20
                }
            }
        ]
    }
};

const topNatureChart = {
    labels: [],
    datasets: [
        {
            label: 'J',
            backgroundColor: '#2096f3',
            data: []
        },
        {
            label: 'J-7',
            backgroundColor: '#a1d2fa',
            data: []
        }
    ]
};

const reqMoisChart = {
    labels: [],
    datasets: []
};

export const Dashboard = () => {
    const appservice = new CISService();
    const [showindicator, setShowindicator] = useState(false);
    const [rstructure, setStructure] = useState('-1');

    const [liste_structure, setListe_structure] = useState([]);
    const [liste_stat_req_canal, setListe_stat_req_canal] = useState(null);
    const [liste_stat_top_nature, setListe_stat_top_nature] = useState(null);
    const [liste_stat_req_mois, setListe_stat_req_mois] = useState(null);
    const [liste_stat_req_motif, setListe_stat_req_motif] = useState(null);
    const [liste_stat_trt_mois, setListe_stat_trt_mois] = useState(null);

    const [nbReqAnnee, setNbReqAnnee] = useState(0);
    const [nbReqJ, setNbReqJ] = useState(0);
    const [nbTrtAnnee, setNbTrtAnnee] = useState(0);
    const [nbTrtJ, setNbTrtJ] = useState(0);
    const [nbTrtDelai, setNbTrtDelai] = useState(0);
    const [nbTrtHdelai, setNbTrtHdelai] = useState(0);
    const [dmt, setDmt] = useState(0);
    const [tauxTrt, setTauxTrt] = useState(0);
    const [nbOuvert, setNbOuvert] = useState(0);
    const [tauxTrtDelai, setTauxTrtDelai] = useState(0);
    const [tauxTrtHdelai, setTauxTrtHdelai] = useState(0);

    const chartRef = useRef(null);
    const chartRefTop = useRef(null);
    const chartRefCanal = useRef(null);


    useEffect(() => {
        refreshDashboard('-1');
    }, []);

    const refreshDashboard = (pIdStruct) => {
        setShowindicator(true);
        appservice.get_liste_structure().then(data => {
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idStruct, label: x.libelleStruct }
                });
                setListe_structure(liste);
            }
        });
        appservice.get_dashboard(pIdStruct).then((data) => {
            setShowindicator(false);
            if (data !== null && data !== undefined) {
                setListe_stat_req_canal(data.liste_stat_req_canal);
                setListe_stat_trt_mois(data.liste_stat_trt_mois);
                setListe_stat_top_nature(data.liste_stat_top_nature);
                setListe_stat_req_mois(data.liste_stat_req_mois);
                setListe_stat_req_motif(data.liste_stat_req_motif);
                setNbReqAnnee(data.nbReqAnnee);
                setTauxTrtHdelai(data.tauxTrtHdelai);
                setNbReqJ(data.nbReqJ);
                setNbTrtAnnee(data.nbTrtAnnee);
                setNbTrtJ(data.nbTrtJ);
                setDmt(data.dmt);
                setNbOuvert(data.nbOuvert);
                setNbTrtDelai(data.nbTrtDelai);
                setTauxTrt(data.tauxTrt);
                setTauxTrtDelai(data.tauxTrtDelai);

                if (data.liste_stat_req_mois !== null && data.liste_stat_req_mois !== undefined) {
                    let liste_mois = data.liste_stat_req_mois.map(x => {
                        return x.label
                    });
                    let liste_value1 = data.liste_stat_req_mois.map(x => {
                        return x.ivalue
                    });
                    let liste_value2 = data.liste_stat_req_mois.map(x => {
                        return x.isubvalue
                    });
                    reqMoisChart.labels = liste_mois;
                    reqMoisChart.datasets = [
                        {
                            label: 'Requêtes',
                            data: liste_value1,
                            backgroundColor: ['#e3f2fd'],
                            borderColor: ['#BBDEFB'],
                            borderWidth: 3,
                            fill: true
                        },
                        {
                            label: 'Traitement',
                            data: liste_value2,
                            backgroundColor: ['#b6e0da'],
                            borderColor: ['#79bbb2'],
                            borderWidth: 3,
                            fill: true
                        }
                    ]

                    if (chartRef.current !== null) {
                        chartRef.current.refresh();
                    }
                }
                if (data.liste_stat_top_nature !== null && data.liste_stat_top_nature !== undefined) {
                    let liste_label = data.liste_stat_top_nature.map(x => {
                        return x.label
                    });
                    let liste_value = data.liste_stat_top_nature.map(x => {
                        return x.ivalue
                    });
                    let liste_valueJm7 = data.liste_stat_top_nature.map(x => {
                        return x.isubvalue
                    });
                    topNatureChart.labels = liste_label;
                    topNatureChart.datasets[0].data = liste_value;
                    topNatureChart.datasets[1].data = liste_valueJm7;
                    if (chartRefTop.current !== null) {
                        chartRefTop.current.refresh();
                    }
                }

                if (data.liste_stat_req_canal !== null && data.liste_stat_req_canal !== undefined) {
                    let liste_label = data.liste_stat_req_canal.map(x => {
                        return x.label
                    });
                    let liste_value = data.liste_stat_req_canal.map(x => {
                        return x.ivalue
                    });

                    doughnutData.labels = liste_label;
                    doughnutData.datasets[0].data = liste_value;
                    if (chartRefCanal.current !== null) {
                        chartRefCanal.current.refresh();
                    }
                }

            }
        });
    }

    const refreshClick = (event) => {
        refreshDashboard(rstructure);
    }

    const changeDataset = (event) => {
        let index = parseInt(event.currentTarget.getAttribute('data-index'));

        let label = event.currentTarget.getAttribute('data-label');
        let border = event.currentTarget.getAttribute('data-stroke');
        let backcolor = event.currentTarget.getAttribute('data-fill');

        let liste_value = [];
        let liste_value_trt = [];
        let liste_mois = [];


        if (index == 0) {
            if (liste_stat_req_mois !== null && liste_stat_req_mois !== undefined && index == 0) {
                liste_mois = liste_stat_req_mois.map(x => {
                    return x.label
                });
                liste_value = liste_stat_req_mois.map(x => {
                    return x.ivalue
                });
                liste_value_trt = liste_stat_req_mois.map(x => {
                    return x.isubvalue
                });
                reqMoisChart.datasets = [
                    {
                        label: 'Requêtes',
                        data: liste_value,
                        backgroundColor: ['#e3f2fd'],
                        borderColor: ['#BBDEFB'],
                        borderWidth: 3,
                        fill: true
                    },
                    {
                        label: 'Traitement',
                        data: liste_value_trt,
                        backgroundColor: ['#b6e0da'],
                        borderColor: ['#79bbb2'],
                        borderWidth: 3,
                        fill: true
                    }
                ]
                reqMoisChart.labels = liste_mois;
            }

        } else if (index == 1) {
            if (liste_stat_trt_mois !== null && liste_stat_trt_mois !== undefined) {
                liste_mois = liste_stat_trt_mois.map(x => {
                    return x.periode
                });
                liste_value = liste_stat_trt_mois.map(x => {
                    return x.nbHdelai
                });

            }
        } else if (index == 2) {
            if (liste_stat_trt_mois !== null && liste_stat_trt_mois !== undefined) {
                liste_mois = liste_stat_trt_mois.map(x => {
                    return x.periode
                });
                liste_value = liste_stat_trt_mois.map(x => {
                    return x.nbDelai
                });
            }
        } else {
            if (liste_stat_req_mois !== null && liste_stat_req_mois !== undefined) {
                liste_mois = liste_stat_req_mois.map(x => {
                    return x.label
                });
                liste_value = liste_stat_req_mois.map(x => {
                    return x.isubvalue2
                });
            }
        }

        if (index !== 0) {
            reqMoisChart.labels = liste_mois;
            reqMoisChart.datasets = [
                {
                    label: label,
                    data: liste_value,
                    backgroundColor: backcolor,
                    borderColor: border,
                    borderWidth: 3,
                    fill: true
                }
            ]
        }

        chartRef.current.refresh();
    };


    const refreshDataset = (event) => {
        //chartRef.current.refresh(event);
        chartRef.current.reinit(event);
    };

    const onOrderTabClick = (event) => {
        changeDataset(event);
        refreshDataset(event);
    };

    const structureChange = (event) => {
        setStructure(event.value);
        refreshDashboard(event.value);
    };


    const leftContents = (
        <React.Fragment>
            {appservice.get_userconnected() !== undefined && appservice.get_userconnected() !== null && appservice.get_userconnected() !== '' ?
                appservice.get_userconnected().idCodifGrp.libelleCodif == 'Administrateur' ?
                    <Dropdown options={[{ label: 'Toutes agences', value: '-1' }].concat(liste_structure)} value={rstructure} optionLabel="label" onChange={structureChange} className="dashbard-demo-dropdown" style={{ width: 250 }} />
                    : null
                : null}
        </React.Fragment>
    );

    const rightContents = (
        <React.Fragment>
            <Button icon="pi pi-refresh" onClick={refreshClick} />
            {showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginLeft: -10 }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
        </React.Fragment>
    );


    return (
        <div className="layout-dashboard">
            {
                appservice.get_userconnected() !== undefined && appservice.get_userconnected() !== null ?
                    <>
                        <Toolbar left={leftContents} right={rightContents} style={{ backgroundColor: '#fff', marginTop: -20, marginBottom: 10 }} />
                    </>
                    : null
            }

            <div className="grid">

                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-1">
                        <span className="overview-icon">
                            <i className="pi pi-comments"></i>
                        </span>
                        <span className="overview-title">Requêtes</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">{nbOuvert}</div>
                                <div className="overview-subtext">Total ouvert</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nbReqJ)}</div>
                                <div className="overview-subtext">Total jour</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
                        <span className="overview-icon">
                            <i className="pi pi-check-square"></i>
                        </span>
                        <span className="overview-title">Traitements</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nbTrtJ)}</div>
                                <div className="overview-subtext">Nb jour</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">{tauxTrt} %</div>
                                <div className="overview-subtext">Taux global</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-3">
                        <span className="overview-icon">
                            <i className="pi pi-bookmark-fill"></i>
                        </span>
                        <span className="overview-title">Efficacité</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">{tauxTrtHdelai}</div>
                                <div className="overview-subtext">Taux H délais</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">{tauxTrtDelai} %</div>
                                <div className="overview-subtext">Taux délais</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-4">
                        <span className="overview-icon">
                            <i className="pi pi-clock"></i>
                        </span>
                        <span className="overview-title">Durées moyennes</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">{dmt}</div>
                                <div className="overview-subtext">Traitement</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">0</div>
                                <div className="overview-subtext">Communication</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 xl:col-6">
                    <div className="card grid-nogutter orders" style={{ height: 520 }}>
                        <i style={{ fontSize: 20 }} className="pi pi-chart-line"></i> <span style={{ fontSize: 22, marginLeft: 10 }}>Statistique des requêtes par mois</span>
                        <hr style={{ marginTop: 8 }} />

                        <div className="grid">
                            <div className="col-12">
                                <div id="order-tabs-container" className="grid order-tabs">
                                    <div className="order-tab order-tab-new col-6 md:col-3" onClick={onOrderTabClick}
                                        data-label="Requêtes" data-index="0" data-stroke="#BBDEFB" data-fill="rgba(100, 181, 246, 0.2)">
                                        <i className="pi pi-comments"></i>
                                        <span className="order-label">Requêtes</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-new.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-completed col-6 md:col-3" onClick={onOrderTabClick}
                                        data-label="Traitement hors délai" data-index="1" data-stroke="#C5CAE9" data-fill="rgba(121, 134, 203, 0.2)">
                                        <i className="pi pi-check-circle"></i>
                                        <span className="order-label">H délais</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-completed.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-refunded col-6 md:col-3" onClick={onOrderTabClick}
                                        data-label="Traitements dans délai" data-index="2" data-stroke="#B2DFDB" data-fill="rgba(224, 242, 241, 0.5)">
                                        <i className="pi pi-refresh"></i>
                                        <span className="order-label">Délai</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-refunded.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-cancelled col-6 md:col-3" onClick={onOrderTabClick}
                                        data-label="Non fondé" data-index="3" data-stroke="#B2EBF2" data-fill="rgba(224, 247, 250, 0.5)">
                                        <i className="pi pi-times-circle"></i>
                                        <span className="order-label">Infondé</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-cancelled.svg" alt="order" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="overview-chart">
                                    <Chart ref={chartRef} type="line" height="215w" data={reqMoisChart} options={ordersChartOptions} id="order-chart"></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-6">
                    <div className="card" style={{ height: 520 }}>
                        <i style={{ fontSize: 20 }} className="pi pi-chart-bar"></i> <span style={{ fontSize: 22, marginLeft: 10 }}>Top 10 des natures</span>
                        <hr style={{ marginTop: 8 }} />

                        <Chart options={barchartOptions} ref={chartRefTop} responsive={true} type="bar" data={topNatureChart} height="410w" />

                    </div>
                </div>


                <div className="col-12 xl:col-6">
                    <div className="card" style={{ height: 500 }}>
                        <i style={{ fontSize: 20 }} className="pi pi-table"></i> <span style={{ fontSize: 22, marginLeft: 10 }}>Requêtes par motif</span>
                        <hr style={{ marginTop: 8 }} />
                        <DataTable value={liste_stat_req_motif} paginator rows={10} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                            <Column field="motif" header="Motif" sortable ></Column>
                            <Column field="nb" header="Nb" style={{ width: '10%' }} sortable ></Column>
                            <Column field="prc" header="Taux (%)" sortable style={{ width: '22%' }}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="col-12 xl:col-6">
                    <div className="card" style={{ height: 500 }}>
                        <i style={{ fontSize: 20 }} className="pi pi-chart-pie"></i> <span style={{ fontSize: 22, marginLeft: 10 }}>Statistiques des requêtes par canal</span>
                        <hr style={{ marginTop: 8 }} />
                        <center>
                            <Chart ref={chartRefCanal} type="doughnut" data={doughnutData} options={pieOptions} style={{ width: '70%' }} />
                        </center>
                    </div>
                </div>

            </div>
        </div>
    );
}
