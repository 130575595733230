import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';

export class FormCommune extends Component {
    constructor() {
        super();  
        this.state = {
            liste_Commune: [],
            liste_Ville: [], 
            selectedCommune: null,
            idCommune: '',
            libelleCommune: '',
            idVille: null, //Clé étrangère de la table Ville 
           

            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_commune = this.edit_commune.bind(this);
        this.conf_delete_commune = this.conf_delete_commune.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService  = new CISService ();
  
    }
 
    componentDidMount() {
        this.get_liste_commune(); 
        this.get_liste_ville();  
    }

    get_liste_commune() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_commune().then(data => this.setState({ liste_Commune: data, showindicator: false }));
    } 

    //Liste des villes
    get_liste_ville() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_ville().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let ville = data.map(x => {
                    return { value: x.idVille, label: x.libelleVille }
                });
                this.setState({ liste_Ville: ville });
            }
        });
    }      


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_commune();
            this.get_liste_commune();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });   
        this.setState({submit: false});
    }
   
    edit_commune(data) {  
        if (data != null && data != undefined) {
            this.setState({ libelleCommune: data.libelleCommune });
            this.setState({ idVille: data.idVille.idVille });
            this.setState({ selectedCommune: data });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_commune() {
        this.setState({ libelleCommune: '' });
        this.setState({ idVille: null });
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
    }


    enreg_commune() {
        this.setState({submit: true});
        if (this.state.libelleCommune == '' || this.state.idVille == null || this.state.idVille == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } 
        else if (this.state.modif == false) {
            this.setState({ showindicator: true });
            let data = {  
                idCommune: 0,
                libelleCommune: this.state.libelleCommune, 
                idVille: {idVille: this.state.idVille} 
            }
            this.CISService.enreg_commune(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedCommune;  
            data.libelleCommune = this.state.libelleCommune;   
            data.idVille = { idVille: this.state.idVille };
            this.CISService.enreg_commune(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_commune(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_commune(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }   

  
    delete_commune() {
        if (this.state.selectedCommune == null || this.state.selectedCommune == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_commune(this.state.selectedCommune).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_commune(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedCommune: data });
            this.setState({ msg: data.idCommune });
            this.setState({ visible: true });
            this.setState({ selectedCommune: data });
        }
    }  

    /* Affichage de la boite de dialogue pour l'ajout Codification */
    toogleDlg() {
        this.annule_commune();  
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }  

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedCommune == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_commune(this.state.selectedCommune)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

     /* Exportation du tableau contenant la liste des communes en fichier CSV */
     exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_commune");
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_commune();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );  
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des Communes  */}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Commune} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedCommune: e.data })} onRowSelect={e => this.setState({ selectedCommune: e.data })}
                    responsive={true} selection={this.state.selectedCommune} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="libelleCommune" filter={true} filterMatchMode='contains' header="Commune" sortable={true} />
                    <Column field="idVille.libelleVille" filter={true} filterMatchMode='contains' header="Ville" sortable={true} style={{ width: '30%' }} />
                    <Column field="idVille.idRegion.libelleRegion" filter={true} filterMatchMode='contains' header="Region" sortable={true} style={{ width: '20%' }} />

                </DataTable>

                {/* Boite de dialogue d'ajout et de modification des Communes */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_commune()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_commune()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="libelleCommune">Commune *</label>
                            <InputText id="libelleCommune" value={this.state.libelleCommune} onChange={(e) => this.setState({ libelleCommune: e.target.value })} required autoFocus />
                            {this.state.submit && !this.state.libelleCommune && <small className="p-invalid" style={{ color: 'red' }}>Libelle </small>}
                        </div>    
                        <div className="field">
                            <label htmlFor="idVille">Ville *</label>
                            <Dropdown id="idVille" options={this.state.liste_Ville} value={this.state.idVille} onChange={(e) => this.setState({ idVille: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                            {this.state.submit && !this.state.idVille && <small className="p-invalid" style={{ color: 'red' }}>Ville .</small>}
                        </div>
                    </div>
                </Dialog>  
            </div>
        );
    }
}