import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';

export class FormQuartier extends Component {
    constructor() {
        super();    
        this.state = {
            liste_Quartier: [],
            liste_Commune: [],
            selectedQuartier: null,
            idQuartier: '',
            libelleQuartier: '',
            idCommune: '', //Clé étrangère de la table Commune
           
  
            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_quartier = this.edit_quartier.bind(this);
        this.conf_delete_quartier = this.conf_delete_quartier.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService  = new CISService ();  
    }     
 
    componentDidMount() {
        this.get_liste_quartier();
        this.get_liste_commune();   
    }

    get_liste_quartier() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_quartier().then(data => this.setState({ liste_Quartier: data, showindicator: false }));
    }   

     //Liste Des Communes
     get_liste_commune() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_commune().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let commune = data.map(x => {
                    return { value: x.idCommune, label: x.libelleCommune }
                });
                this.setState({ liste_Commune: commune });
            }
        });
    }  


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_quartier();
            this.get_liste_quartier();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });   
        this.setState({submit: false});  
    }
   
    edit_quartier(data) {  
        if (data != null && data != undefined) {
            this.setState({ libelleQuartier: data.libelleQuartier });
            this.setState({ idCommune: data.idCommune.idCommune });
            this.setState({ selectedQuartier: data });
            this.setState({ visible_dlg_form: true });
            this.setState({ modif: true });
        }
    }

    annule_quartier() {
        this.setState({ libelleQuartier: '' });
        this.setState({ idCommune: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_form: false });
    }

  
    enreg_quartier() {
        this.setState({submit: true});
        if (this.state.libelleQuartier == ''  || this.state.idCommune == null || this.state.idCommune == undefined) {  
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } 
        else if (this.state.modif == false) {
            this.setState({ showindicator: true }); 

            let data = {  
                idQuartier: 0,
                libelleQuartier: this.state.libelleQuartier, 
                idCommune: {idCommune: this.state.idCommune}
            }
            this.CISService.enreg_quartier(data).then(data => this.resultat(data.code, data.contenu));
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedQuartier;  
            data.libelleQuartier = this.state.libelleQuartier;
            data.idCommune = {idCommune: this.state.idCommune}; 
            this.CISService.enreg_quartier(data).then(data => this.resultat(data.code, data.contenu));
        }
    }   

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_quartier(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_quartier(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }   

  
    delete_quartier() {
        if (this.state.selectedQuartier == null || this.state.selectedQuartier == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_quartier(this.state.selectedQuartier).then(data => this.resultat(data.code, data.contenu));
        }
    }   

    conf_delete_quartier(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedQuartier: data });
            this.setState({ msg: data.idQuartier });
            this.setState({ visible: true });
            this.setState({ selectedQuartier: data });
        }
    }  
  
    /* Affichage de la boite de dialogue pour l'ajout Quartier */
    toogleDlg() {
        this.annule_quartier();  
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }  

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedQuartier == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_quartier(this.state.selectedQuartier)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_quartier();  
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );  
        return (
            <div className="card p-fluid col-12">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des Quartiers*/}
                <DataTable value={this.state.liste_Quartier} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedQuartier: e.data })} onRowSelect={e => this.setState({ selectedQuartier: e.data })}
                    responsive={true} selection={this.state.selectedQuartier} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                    <Column field="libelleQuartier" filter={true} filterMatchMode='contains' header="Quartier" sortable={true}  />
                    <Column field="idCommune.libelleCommune" filter={true} filterMatchMode='contains' header="Commune" sortable={true} style={{ width: '20%' }} />
                    <Column field="idCommune.idVille.libelleVille" filter={true} filterMatchMode='contains' header="Ville" sortable={true} style={{ width: '20%' }} />
                    <Column field="idCommune.idVille.idRegion.libelleRegion" filter={true} filterMatchMode='contains' header="Region" sortable={true} style={{ width: '20%' }} />
                </DataTable>  

                {/* Boite de dialogue d'ajout et de modification Des Quartiers */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_quartier()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_quartier()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="libelleQuartier">Quartier *</label>
                            <InputText id="libelleQuartier" value={this.state.libelleQuartier} onChange={(e) => this.setState({ libelleQuartier: e.target.value })} required autoFocus />
                            {this.state.submit && !this.state.libelleQuartier && <small className="p-invalid" style={{ color: 'red' }}>Libelle </small>}
                        </div>  
                        <div className="field">
                            <label htmlFor="idCommune">Commune *</label>
                            <Dropdown id="idCommune" options={this.state.liste_Commune} value={this.state.idCommune} onChange={(e) => this.setState({ idCommune: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                            {this.state.submit && !this.state.idCommune && <small className="p-invalid" style={{ color: 'red' }}>Commune.</small>}
                        </div>  
                    </div>  
                </Dialog>   
            </div>
        );
    }
}