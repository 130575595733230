import React, { Component } from 'react';
import CISService from '../service/CISService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';


export class FormPersonnel extends Component {
    constructor() {
        super();
        this.state = {
            liste_Personnel: [],
            liste_fonction: [],
            liste_structure: [],
            selectedPersonnel: null,
            idStruct: null,
            matrPers: '',
            nomPers: '',
            prenomPers: '',
            telPers: '',
            lieuNaissPers: '',
            dateEmbPers: new Date(),
            dateFinPers: null,
            dateNaissPers: new Date(),
            lieuNaissPers: '',
            etatMatrimPers: '',
            sexePers: null,
            file: null,
            urlfile: null,
            type_sexe: [
                { label: 'Homme', value: 'H' },
                { label: 'Femme', value: 'F' }
            ],
            checkPhone: true, //Véfifier si le numéro existe déja
            niveauPers: '',
            adrPers: '',
            photoPers: '',
            statutPers: '',
            idCodifFct: null, //Clé étrangère de la table Codification
            ////////////////////////////
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout un personnel
            visible: false, // pour afficher la boite de confirmation de suppression d'un personnel
            visible_dlg_form: false, // pour afficher la boite de dialogue du formulaire de saisie des personnels
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_personnel = this.edit_personnel.bind(this);
        this.conf_delete_personnel = this.conf_delete_personnel.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.CISService = new CISService();
    }

    componentDidMount() {
        this.get_liste_personnel();
        this.get_liste_structure();
    }

    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_personnel().then(data => this.setState({ liste_Personnel: data, showindicator: false }));
    }

    //Liste Des structures
    get_liste_structure() {
        this.setState({ showindicator: true });
        this.CISService.get_liste_structure().then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let structures = data.map(x => {
                    return { value: x.idStruct, label: x.libelleStruct }
                });
                this.setState({ liste_structure: structures });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_personnel();
            this.get_liste_personnel();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.setState({ submit: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_personnel(data) {
        if (data != null && data != undefined) {
            this.setState({ nomPers: data.nomPers });
            this.setState({ prenomPers: data.prenomPers });
            this.setState({ telPers: data.telPers });
            this.setState({ lieuNaissPers: data.lieuNaissPers });
            this.setState({ emailPers: data.emailPers });
            this.setState({ dateNaissPers: data.dateNaissPers });
            this.setState({ dateEmbPers: data.dateEmbPers });
            this.setState({ dateFinPers: data.dateFinPers });
            this.setState({ idStruct: data.idStruct.idStruct });
            this.setState({ sexePers: data.sexePers });
            this.setState({ niveauPers: data.niveauPers });
            this.setState({ adrPers: data.adrPers });
            this.setState({ photoPers: data.photoPers });
            this.setState({ statutPers: data.statutPers });
            this.setState({ selectedPersonnel: data });
            this.setState({ visible_dlg_form: true });
            this.setState({ modif: true });

            if (data.photoPers !== null && data.photoPers !== '') {
                this.setState({ urlfile: this.CISService.download_file(data.photoPers) })
            } else {
                this.setState({ urlfile: null })
            }
        }
    }

    annule_personnel() {
        this.setState({ nomPers: '' });
        this.setState({ prenomPers: '' });
        this.setState({ telPers: '' });
        this.setState({ lieuNaissPers: '' });
        this.setState({ emailPers: '' });
        this.setState({ dateNaissPers: null });
        this.setState({ dateEmbPers: null });
        this.setState({ dateFinPers: null });
        this.setState({ lieuNaissPers: '' });
        this.setState({ sexePers: null });
        this.setState({ niveauPers: '' });
        this.setState({ adrPers: null });
        this.setState({ photoPers: null });
        this.setState({ statutPers: '' });
        this.setState({ modif: false });
        this.setState({ urlfile: null });
        this.setState({ file: null });
        this.setState({ visible_dlg_form: false });

    }


    enreg_personnel() {
        this.setState({ submit: true });
        if (this.state.nomPers == '' || this.state.prenomPers == '' || this.state.telPers == '' || this.state.dateNaissPers == '' || this.state.lieuNaissPers == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoPers;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PH_' + this.CISService.get_userconnected().idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }
            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                if (this.state.checkPhone == false) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir un autre numéro de téléphone' });
                    return;
                }
                let data = {
                    matrPers: 0,
                    nomPers: this.state.nomPers,
                    prenomPers: this.state.prenomPers,
                    telPers: this.state.telPers,
                    lieuNaissPers: this.state.lieuNaissPers,
                    emailPers: this.state.emailPers,
                    perePers: this.state.perePers,
                    merePers: this.state.merePers,
                    dateNaissPers: this.state.dateNaissPers,
                    lieuNaissPers: this.state.lieuNaissPers,
                    sexePers: this.state.sexePers,
                    niveauPers: this.state.niveauPers,
                    adrPers: this.state.adrPers,
                    photoPers: nomfic,
                    statutPers: this.state.statutPers,
                    idStruct: { idStruct: this.state.idStruct },
                }
                this.CISService.enreg_personnel(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            } else {
                this.setState({ showindicator: true });
                let data = this.state.selectedPersonnel;
                data.nomPers = this.state.nomPers;
                data.prenomPers = this.state.prenomPers;
                data.telPers = this.state.telPers;
                data.lieuNaissPers = this.state.lieuNaissPers;
                data.emailPers = this.state.emailPers;
                data.dateNaissPers = this.state.dateNaissPers;
                data.dateEmbPers = this.state.dateEmbPers;
                data.dateFinPers = this.state.dateFinPers;
                data.lieuNaissPers = this.state.lieuNaissPers;
                data.sexePers = this.state.sexePers;
                data.niveauPers = this.state.niveauPers;
                data.adrPers = this.state.adrPers;
                data.photoPers = nomfic;
                data.statutPers = this.state.statutPers;
                data.idStruct = { idStruct: this.state.idStruct };
                this.CISService.enreg_personnel(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        if (reponse.code == 200) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                        }
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 130 }}>
            <div className="col"><Button disabled={this.CISService.get_profil() !== 'Administrateur'} onClick={() => this.edit_personnel(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button disabled={this.CISService.get_profil() !== 'Administrateur'} onClick={() => this.conf_delete_personnel(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -17 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_personnel() {
        if (this.state.selectedPersonnel == null || this.state.selectedPersonnel == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.CISService.delete_personnel(this.state.selectedPersonnel).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_personnel(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPersonnel: data });
            this.setState({ msg: data.matrPers });
            this.setState({ visible: true });
            this.setState({ selectedPersonnel: data });
        }
    }


    /* Affichage de la boite de dialogue pour l'ajout Personnel */
    toogleDlg() {
        this.annule_personnel();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }


    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedPersonnel == null || this.CISService.get_userconnected().profilUser !== 'Administrateur'} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_personnel(this.state.selectedPersonnel)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

     /* Exportation du tableau contenant la liste du personnel en fichier CSV */
     exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_personnel");
    }

    /* Uploader sur le serveur le logo */
    fileUpload(file, namefile) {
        this.CISService.upload_file(file, namefile).then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }


    /* Colonne d'affichage de la photo */
    photoTemplate(rowData, column) {
        let logoFileName = rowData.photoPers;
        if (logoFileName == '' || logoFileName == null || logoFileName == undefined) {
            logoFileName = 'nologo.png';
        }
        var src = this.CISService.download_file(logoFileName);
        return <img src={src} alt={rowData.label} style={{ width: '50px', height: '60px', borderRadius: 10, backgroundColor: '#f7f7f7' }} />;
    };

    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_personnel();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="card p-fluid col-12" style={{height: 900}}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                {/* Tableau contenant la liste des Personnels*/}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_Personnel} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedPersonnel: e.data })} onRowSelect={e => this.setState({ selectedPersonnel: e.data })}
                    responsive={true} selection={this.state.selectedPersonnel} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column field="No" body={(rowData, props) => {
                        return <div>
                            <span>{props.rowIndex + 1}</span>
                        </div>
                    }} header="No" style={{ width: '4%' }} />
                    <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} exportable={false} />
                    <Column field="matrPers" filter={true} filterMatchMode='contains' header="Matricule" sortable={true} style={{ width: '10%' }}/>
                    <Column field="nomPers" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '10%' }}/>
                    <Column field="prenomPers" filter={true} filterMatchMode='contains' header="Prenom" sortable={true} />
                    <Column field="telPers" filter={true} filterMatchMode='contains' header="Téléphone"  style={{ width: '10%' }} />
                    <Column field="statutPers" filter={true} filterMatchMode='contains' header="Statut"  style={{ width: '15%' }} />
                    <Column field="idStruct.libelleStruct" filter={true} filterMatchMode='contains' header="Agence" sortable={true} style={{ width: '15%' }} />
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                </DataTable>

                {/* Boite de dialogue d'ajout et de modification Des Personnels */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '70%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_personnel()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_personnel()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="formgrid grid">
                            <div className="field col-4">
                                <label htmlFor="telPers">Téléphone *</label>
                                <InputMask autoFocus mask="999999999" id="telPers" value={this.state.telPers} onChange={(e) => this.setState({ telPers: e.target.value })}  required className={classNames({ 'p-invalid': (this.state.submit && !this.state.telPers) || this.state.submit && !this.state.checkPhone })} />
                                {this.state.submit && !this.state.telPers && <small className="p-invalid" style={{ color: 'red' }}>Le numéro de téléphone est obligatoire.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="nomPers">Nom *</label>
                                <InputText id="nomPers" value={this.state.nomPers} onChange={(e) => this.setState({ nomPers: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.nomPers })} />
                                {this.state.submit && !this.state.nomPers && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="prenomPers">Prénom *</label>
                                <InputText id="prenomPers" value={this.state.prenomPers} onChange={(e) => this.setState({ prenomPers: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.prenomPers })} />
                                {this.state.submit && !this.state.prenomPers && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire. </small>}
                            </div>

                            <div className="field col-4">
                                <label htmlFor="emailPers">Email </label>
                                <InputText id="emailPers" value={this.state.emailPers} onChange={(e) => this.setState({ emailPers: e.target.value })} />
                            </div>
                            <div className="field col-4">
                                <label htmlFor="dateNaissPers">Date de naissance  *</label>
                                <InputMask mask="99/99/9999" id="dateNaissPers" value={this.state.dateNaissPers} onChange={(e) => this.setState({ dateNaissPers: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.dateNaissPers })} />
                                {this.state.submit && !this.state.dateNaissPers && <small className="p-invalid" style={{ color: 'red' }}>La date de naissance est obligatoire.  </small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="lieuNaissPers">Lieu de naissance *</label>
                                <InputText id="lieuNaissPers" value={this.state.lieuNaissPers} onChange={(e) => this.setState({ lieuNaissPers: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.lieuNaissPers })} />
                                {this.state.submit && !this.state.lieuNaissPers && <small className="p-invalid" style={{ color: 'red' }}>Le lieu de Naissance est obligatoire.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="sexePers">Sexe *</label>
                                <Dropdown options={[
                                    { label: 'Masculin', value: 'Masculin' },
                                    { label: 'Feminin', value: 'Feminin' },
                                ]} id="sexePers" value={this.state.sexePers} onChange={(e) => this.setState({ sexePers: e.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.sexePers })} />
                                {this.state.submit && !this.state.sexePers && <small className="p-invalid" style={{ color: 'red' }}>Le genre est obligatoire </small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="niveauPers">Niveau d'étude *</label>
                                <Dropdown id="region" options={[
                                    { label: 'Universitaire', value: 'Universitaire' },
                                    { label: 'Professionel', value: 'Professionel' },
                                    { label: 'Pré-universitaire', value: 'Pré-universitaire' },
                                ]} value={this.state.niveauPers} onChange={(e) => this.setState({ niveauPers: e.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.niveauPers })} />
                                {this.state.submit && !this.state.niveauPers && <small className="p-invalid" style={{ color: 'red' }}>Le niveau d'étude est obligatoire .</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="idQuart">Bureau / agence *</label>
                                <Dropdown id="idQuart" options={this.state.liste_structure} value={this.state.idStruct} onChange={(e) => this.setState({ idStruct: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.idStruct })} />
                                {this.state.submit && !this.state.idStruct && <small className="p-invalid" style={{ color: 'red' }}>Le bureau est obligatoire.</small>}
                            </div>

                            <div className="field col-8">
                                <div className="formgrid grid">
                                    <div className="field col-6">
                                        <label htmlFor="dateNaissPers">Date d'embauche</label>
                                        <InputMask mask="99/99/9999" id="dateEmbPers" value={this.state.dateEmbPers} onChange={(e) => this.setState({ dateEmbPers: e.target.value })} required className={classNames({ 'p-invalid': this.state.submit && !this.state.dateEmbPers })} />
                                        {this.state.submit && !this.state.dateEmbPers && <small className="p-invalid" style={{ color: 'red' }}>La date d'embauche est obligatoire.  </small>}
                                    </div>

                                    <div className="field col-6">
                                        <label htmlFor="dateFinPers">Date de départ  </label>
                                        <InputMask mask="99/99/9999" id="dateFinPers" value={this.state.dateFinPers} onChange={(e) => this.setState({ dateFinPers: e.target.value })} />
                                    </div>
                                    <div className="field col-6">
                                        <label htmlFor="adrlPers">Adresse </label>
                                        <InputText id="adrPers" value={this.state.adrPers} onChange={(e) => this.setState({ adrPers: e.target.value })} />
                                    </div>
                                    <div className="field col-6">
                                        <label htmlFor="idQuart">Statut *</label>
                                        <Dropdown id="idQuart" options={
                                            [
                                                { label: 'EN POSTE', value: 'EN POSTE' },
                                                { label: 'EN ATTENTE', value: 'EN ATTENTE' },
                                                { label: 'DEMISSION', value: 'DEMISSION' },
                                                { label: 'LICENCIE', value: 'LICENCIE' },
                                            ]} value={this.state.statutPers} onChange={(e) => this.setState({ statutPers: e.target.value })} filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.statutPers })} style={{width: '100%'}} autoWidth={false} />
                                        {this.state.submit && !this.state.statutPers && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire.</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="field col-4">
                                <label htmlFor="photoPers">Photo *</label>
                                <div className="col-12 md:col-9">
                                    <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                        <div style={{ height: 90, width: 90, borderRadius: 10 }} >
                                            <center>
                                                {this.state.urlfile == null ?
                                                    <div style={{ width: '90px', height: '90px', borderRadius: 10, backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                    <img src={this.state.urlfile} style={{ width: '90px', height: '90px', borderRadius: 10, backgroundColor: '#f7f7f7' }} />}
                                            </center>
                                        </div>
                                    </a>
                                    <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            this.setState({ file: e.target.files[0] }, () => {
                                                let url = URL.createObjectURL(this.state.file);
                                                this.setState({ urlfile: url });
                                            })
                                        }
                                    }} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>



            </div>
        );
    }
}